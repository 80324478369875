<template>
  <div class="SystemSettings_I">
    <div class="headNavs">
      <div>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect_top">
          <el-menu-item index="1">基础配置</el-menu-item>
          <el-menu-item index="2">营销配置</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：包含基础配置及营销配置</div>
    </div>
    
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="headerEdit()"><i class="el-icon-plus"></i>编辑</div>
      </div>
      <div v-if="isShow == 1">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>网站名称</div>
          <div>{{basiclist.name}}</div>
        </div>
        <div class="ToConfigure">
          <div>SEO标题</div>
          <div>{{basiclist.seo_title}}</div>
        </div>
        <div class="ToConfigure">
          <div>SEO关键字</div>
          <div>{{basiclist.seo_keyword}}</div>
        </div>
        <div class="ToConfigure">
          <div>SEO描述</div>
          <div class="details">{{basiclist.seo_description}}</div>
        </div>
        <div class="ToConfigure_A">
          <div>网站LOGO</div>
          <div class="AAAA">
            <div>{{basiclist.logo}}</div>
            <img :src="basiclist.full_logo" alt="">
          </div>
        </div>
        <div class="ToConfigure_A">
          <div>首页背景图</div>
          <div class="AAAA">
            <div>{{basiclist.home_bg}}</div>
            <img :src="basiclist.full_home_bg" alt="">
          </div>
        </div>
        <div class="ToConfigure">
          <div>备案号</div>
          <div>{{basiclist.beian}}</div>
        </div>
        <div class="ToConfigure">
          <div>版权信息</div>
          <div>{{basiclist.copyright}}</div>
        </div>
      </div>
      <div v-if="isShow == 2">
        <div class="ToConfigure" style="border-top: 1px solid #eee;">
          <div>公司名称</div>
          <div>{{marketlist.company}}</div>
        </div>
        <div class="ToConfigure">
          <div>公司地址</div>
          <div>{{marketlist.address}}</div>
        </div>
        <div class="ToConfigure">
          <div>经纬度</div>
          <div>{{marketlist.long_lat}}</div>
        </div>
        <div class="ToConfigure">
          <div>电话号码</div>
          <div>{{marketlist.telephone}}</div>
        </div>
        <div class="ToConfigure">
          <div>手机号码</div>
          <div>{{marketlist.mobile}}</div>
        </div>
        <div class="ToConfigure">
          <div>邮箱</div>
          <div>{{marketlist.email}}</div>
        </div>
        <div class="ToConfigure">
          <div>微信</div>
          <div>{{marketlist.weixin}}</div>
        </div>
        <div class="ToConfigure">
          <div>QQ</div>
          <div>{{marketlist.qq}}</div>
        </div>
        <div class="ToConfigure_A">
          <div>公众号二维码</div>
          <div class="AAAA">
            <div>{{marketlist.weixin_qrocde}}</div>
            <img :src="marketlist.full_weixin_qrocde" alt="">
          </div>
        </div>
        <div class="ToConfigure_A">
          <div>小程序码</div>
          <div class="AAAA">
            <div>{{marketlist.wxapp_qrocde}}</div>
            <img :src="marketlist.full_wxapp_qrocde" alt="">
          </div>
        </div>
      </div>
    </div>


    <div class="show" v-if="editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="editPanel = false"><i class="el-icon-close"></i></div>
        </div>
        <div class="headNavs" style="padding:0 26px">
          <el-menu
            :default-active="acndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect2">
            <el-menu-item index="1">基础配置</el-menu-item>
            <el-menu-item index="2">营销配置</el-menu-item>
          </el-menu>
        </div>
        <el-form :model="Row" :rules="rules" ref="ruleFormRef">
          <div class="EditNewForm" v-if="is_info == 1">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>网站名称</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.name" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO标题</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.seo_title" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO关键字</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.seo_keyword" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO描述</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.seo_description" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>备案号</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.beian" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>版权信息</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="basic.copyright" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">网站LOGO</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-logo"
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList"
                    :on-success="handleSuccess"
                    :on-exceed="handleExceed"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传头像</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">首页背景图</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-home_bg"
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList1"
                    :on-success="handleSuccess1"
                    :on-exceed="handleExceed1"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传头像</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="EditNewForm" v-if="is_info == 2">
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>公司名称</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.company" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>公司地址</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.address" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>经纬度
                <span @click="TJAdd()" style="color: #1890FF;margin-left:30px;cursor: pointer;">选择坐标</span>
              </div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.long_lat" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>电话号码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.telephone" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>手机号码</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.mobile" placeholder="请填写名称"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>邮箱</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.email" placeholder="请填写名称" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>微信</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.weixin" placeholder="请填写名称" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName"><span class="signSpan">* </span>QQ</div>
              <div class="EditNewFormItemIpt">
                <el-input v-model="market.qq" placeholder="请填写名称" class="El_ii"></el-input>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">公众号二维码</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-weixin_qrocde"
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList2"
                    :on-success="handleSuccess2"
                    :on-exceed="handleExceed2"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传头像</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
            <el-form-item class="EditNewFormBtns">
              <div class="EditNewFormItemName">小程序码</div>
              <div class="EditNewFormItemIpt">
                <div class="Picture">
                  <el-upload
                    ref="upload-wxapp_qrocde"
                    list-type="picture-card"
                    :action="upload"
                    :headers="heads"
                    :limit="1"
                    :file-list="fileList3"
                    :on-success="handleSuccess3"
                    :on-exceed="handleExceed3"
                    :on-preview="handlePreview">
                    <i class="el-icon-plus"></i>
                    <div class="elsff">上传头像</div>
                  </el-upload>
                </div>
              </div>
            </el-form-item>
          </div>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="editPanel = false">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <!-- 添加位置 -->
    <el-dialog :title="titleMath" v-model="editVisible" width="35%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="详细地址">
          <el-input v-model="Location.address"></el-input>
        </el-form-item>
        <div id="container"></div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="Close(1)">取 消</el-button>
          <el-button type="primary" @click="positionAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
import AMapLoader from '@amap/amap-jsapi-loader'
	window._AMapSecurityConfig = {
  		securityJsCode: '0e421469d1396f611c38c84171bede50'
	}
export default {
  name: "index",
  data() {
    return {
      editVisible: false,
      Location:{
        name:'',
        address:'',
        latitude:'',
        longitude:'',
      },
      upload: 'https://rest-api.duxin365.com/admin/ajax/upload',
      heads: {token: ''},
      fileList:[],
      fileList1:[],
      fileList2:[],
      fileList3:[],
      basiclist:{},
      marketlist:{},
      //基础配置
      basic:{
        name:'',
        beian:'',
        logo:'',
        copyright:'',
        seo_title:'',
        seo_keyword:'',
        seo_description:'',
        home_bg:'',
      },
     //营销配置
      market:{
        company:'',
        address:'',
        weixin_qrocde:'',
        wxapp_qrocde:'',
        telephone:'',
        mobile:'',
        email:'',
        weixin:'',
        long_lat:'',
        qq:'',
      },
      isShow:1,
      is_info:1,
      activeIndex:'1',
      acndex:1,
      editPanel:false
    }
  },
  mounted() {
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    this.loadTable();
  },
  methods: {

    positionAdd(){      //弹框确认
      var that = this
      var arr = that.Location
      that.editVisible = false
      console.log(arr)
      that.market.long_lat = arr.latitude + ',' + arr.longitude
    },
           
    TJAdd(e){           //弹框打开
      var that = this
        that.titleMath = '添加位置'
        that.editVisible = true
        that.initMap();

    },
    initMap() {
      AMapLoader.load({
        key: "3d62b06249da3d2faabfb630eb3916b0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {

          window.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 11, //初始化地图级别
            center: [118.771409, 32.067939], //初始化地图中心点位置
          });
                    
                    // 设置点样式
          this.icon = new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // Icon的图像
              offset: new AMap.Pixel(-13, -30)
          });
          let geocoder
          AMap.plugin('AMap.Geocoder',function(){
            geocoder = new AMap.Geocoder()
          })

          this.geocoder = geocoder
          var that= this
          that.map.on('click', function (e) {
                      
            var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()];
            that.openPop(lnglatXY)
          });

          // this.map.on('click',this.openPop); // 给点标记添加点击事件，写入对应函数
          
          // this.map.add(this.maker); // 添加点标记
                    console.log(this.geocoder)
        })
        .catch((e) => {
          console.log(e);
        });
    },
    openPop(e) {
        var that = this
        console.log('asd')
        that.markersPosition = e
        that.Location.latitude = e[0]
        that.Location.longitude = e[1]
        // 设置新的标记
        console.log(e)
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
          });
          console.log(geocoder)
          geocoder.getAddress(e, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              var address = result.regeocode.formattedAddress;
              // that.address = address  //兑换地址
              console.log(result)
              that.Location.name = result.regeocode.pois[0].name  
              that.Location.address = result.regeocode.formattedAddress
              // that.$emit('getaddress', that.address, e)
            } else {
              // ElMessage.error('无地址，请重新选择');
              console.log(status)
            }
          });
          // 清除所有标记
          that.map.clearMap();
          var marker = new AMap.Marker();
          that.map.add(marker);
          marker.setPosition(e);
        })
    },
    addMarker () {
      //   经度 纬度
      let lng = Math.random() * 135.05 + 73.3
      let lat = Math.random() * 53.33 + 3.51
      console.log('添加标记', [lng, lat])
      // 添加标记
      this.map.setFitView()

      let marker = new AMap.Marker({
        map: this.map,
        position: [lng, lat]
        // content: markerContent
        // eslint-disable-next-line
                // offset: new AMap.Pixel(-13, -30)
      })
      // 将 markers 添加到地图
      this.markers.push(marker)

      this.map.setFitView()`在这里插入代码片`

      // 鼠标点击marker弹出自定义的信息窗体
      // eslint-disable-next-line no-undef
      AMap.event.addListener(marker, 'click', function (e) {
        console.log('点击marker', e)
      })
    },
    
    loadTable() {
      var that = this
      axios({
        method: 'get',
        url: '/admin/general/config/index',
      }).then(function (res) {
        that.basiclist =  res.data.data.config.basic
        that.marketlist =  res.data.data.config.market
      })
    },
    //导航选择
    handleSelect_top(e){
      var that = this
      that.activeIndex = e
      if(e == 1){
        that.isShow = 1
      } else if(e == 2){
        that.isShow = 2
      }
    },
    handleSelect2(e){
      var that = this
      that.acndex = e
      if(e == 1){
        that.is_info = 1
      } else if(e == 2){
        that.is_info = 2
      }
    },
    //详情
    headerEdit(){
      var that = this 
      that.editPanel = true
      that.acndex = 1
      that.is_info = 1
      axios({
        method: 'get',
        url: '/admin/general/config/index',
      }).then(function (res) {
        that.basic =  res.data.data.config.basic
        that.market =  res.data.data.config.market
        //图片
        let fileArr = [];
        let fileObj = {};
        fileObj.name = "file";
        fileObj.url = res.data.data.config.basic.full_logo;
        fileArr.push(fileObj);
        that.fileList = fileArr
        //图片
        let fileArr1 = [];
        let fileObj1 = {};
        fileObj1.name = "file";
        fileObj1.url = res.data.data.config.basic.full_home_bg;
        fileArr1.push(fileObj);
        that.fileList1 = fileArr1
        //图片
        let fileArr2 = [];
        let fileObj2 = {};
        fileObj2.name = "file";
        fileObj2.url = res.data.data.config.market.full_weixin_qrocde;
        fileArr2.push(fileObj);
        that.fileList2 = fileArr2
        //图片
        let fileArr3 = [];
        let fileObj3 = {};
        fileObj3.name = "file";
        fileObj3.url = res.data.data.config.market.full_wxapp_qrocde;
        fileArr3.push(fileObj);
        that.fileList3 = fileArr3
      })
    },
    //编辑
    BtnEdit(){
      var that = this
      let row = {}
      row['basic'] = that.basic
      row['market'] = that.market
      axios({
        method: 'post',
        url: '/admin/general/config/edit',
        data:{
         row 
        }
      }).then(function(res){
        that.$message.success(res.data.msg);
        that.editPanel = false
        that.loadTable();
      })
    },
    //上传
    handleSuccess(e) {
      console.log(e);
      var that = this
      that.basic.logo = e.data.url
    },
    handleSuccess1(e) {
      console.log(e);
      var that = this
      that.basic.home_bg = e.data.url
    },
    handleSuccess2(e) {
      console.log(e);
      var that = this
      that.market.weixin_qrocde = e.data.url
    },
    handleSuccess3(e) {
      console.log(e);
      var that = this
      that.market.wxapp_qrocde = e.data.url
    },
    handleExceed  (files)  {
      this.$refs["upload-logo"].clearFiles()
      this.$refs["upload-logo"].handleStart(files[0])
      this.$refs["upload-logo"].submit();
    },
    handleExceed1  (files)  {
      this.$refs["upload-home_bg"].clearFiles()
      this.$refs["upload-home_bg"].handleStart(files[0])
      this.$refs["upload-home_bg"].submit();
    },
    handleExceed2  (files)  {
      this.$refs["upload-weixin_qrocde"].clearFiles()
      this.$refs["upload-weixin_qrocde"].handleStart(files[0])
      this.$refs["upload-weixin_qrocde"].submit();
    },
    handleExceed3  (files)  {
      this.$refs["upload-wxapp_qrocde"].clearFiles()
      this.$refs["upload-wxapp_qrocde"].handleStart(files[0])
      this.$refs["upload-wxapp_qrocde"].submit();
    },
    
    handlePreview(file) {
      window.open(file.url);
    },
  }
}
</script>

<style scoped>
.AAAA img{
  width: 90px;
  height: 90px;
  margin: 10px 0;
}
.ToConfigure_A{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
}
.ToConfigure_A div:nth-child(1){
  width: 15%;
  text-align: center;
}
.ToConfigure_A .AAAA{
  width: 50%;
}
.ToConfigure_A .AAAA div:nth-child(1){
  height: 32px;
  line-height: 32px;
  margin: 12px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-indent: 1em;
  color: #555555;
  text-align: left;
  width: 100%;
}
.ToConfigure div:nth-child(2) img{
  display: block;
}
.ToConfigure .AAAA{  
  height: 170px;
}
#container {
  width: 100%;
  height: 400px;
  background: #d9d9d9;
}
</style>